import Konva from 'konva';
import { vectorBackground } from 'assets/icons';
import { layer, editPermission, floorplanDetails } from '../CanvasDrawFn';
import { GEOMETRY_TYPE_ICON, GEOMETRY_TYPE_ICONS_GROUP } from '../consts';
import { shapeContextMenu } from './Actions/shapeContextMenu';
import { drawLabel } from './Label';
import { WELL_PROPORTIONED_AREA } from 'utilities/constants';

// These scaling ratios are calculated by dividing the area of icon shapes by the area of the floorplan image in a well-proportioned map
const scalingRatioIconImage = (54 ** 2 / WELL_PROPORTIONED_AREA);
const scalingRatioIconBackground = (105 ** 2 / WELL_PROPORTIONED_AREA);

export const drawIcon = async (shape) => {
	const floorplanArchived = !!floorplanDetails?.archivedBy;
	const shapeIcon = shape.icon;

	const floorplanArea = floorplanDetails.width * floorplanDetails.height;

	if (shapeIcon) {
		// create konva group
		const konvaIconGroup = new Konva.Group({
			shape,
			x: shape.shape_position_x,
			y: shape.shape_position_y,
			visible: shape.visible,
			draggable: editPermission && !floorplanArchived,
			listening: true,
			scalable: false,
		});

		// draw icon background pinned image
		const iconBackground = drawBackgroundPinnedImg(shape, floorplanArea);
		konvaIconGroup.add(iconBackground);

		// draw icon
		const icon = await drawIconImage(shape, iconBackground, floorplanArea);
		konvaIconGroup.add(icon);

		// draw label
		if (shape.showLabel) {
			const iconLabel = drawLabel(shape.title, iconBackground, 30);
			konvaIconGroup.add(iconLabel);
		}

		if (editPermission) {
			shapeContextMenu(konvaIconGroup);
		}

		//add group to layer
		layer.add(konvaIconGroup);
		return konvaIconGroup;
	}
	return null;
};

const drawIconImage = async (shape, iconBackground, floorplanArea) => {
	// set icon angle
	const angle = shape.angle ? shape.angle : 0;

	// Calculate the position to centralize the icon within the iconBackground
	const rotatedPosX = iconBackground.x() + iconBackground.width() / 2 - Math.cos(angle);
	const rotatedPosY = iconBackground.y() + iconBackground.height() / 2 - Math.sin(angle);

	const iconImg = new Image();
	iconImg.src = shape.icon.iconURL;
	await iconImg.decode();

	const iconImageWidth = Math.sqrt(scalingRatioIconImage * floorplanArea);
	return new Konva.Image({
		shape,
		id: `${shape.id}`,
		x: rotatedPosX,
		y: rotatedPosY,
		offsetX: iconImageWidth / 2 - 1, // Center the icon
		offsetY: iconImageWidth / 2 + 2, // Center the icon
		height: iconImageWidth,
		width: iconImageWidth,
		draggable: false,
		listening: false,
		type: GEOMETRY_TYPE_ICON,
		image: iconImg,
		rotation: angle,
	});
};

const drawBackgroundPinnedImg = (shape, floorplanArea) => {
	const bgImg = new Image();
	bgImg.src = vectorBackground;

	const iconBackgroundWidth = Math.sqrt(scalingRatioIconBackground * floorplanArea);
	return new Konva.Image({
		shape,
		id: `iconBackground-${shape.id}`,
		width: iconBackgroundWidth,
		height: iconBackgroundWidth,
		draggable: false,
		rotation: 0, // Set rotation to 0 to prevent rotation
		type: GEOMETRY_TYPE_ICONS_GROUP,
		image: bgImg,
	});
};
