import React, { useContext, useEffect, useState } from 'react';
import { RapidAlarmService } from 'services';
import { BootstrapedTable, sortingStyle, Checkbox, TableCell } from 'componentsV2';
import {
	ALARM_STAFF_STATUS_ACCOUNTED,
	ALARM_STAFF_STATUS_NEED_ASSISTANCE,
	ALARM_STAFF_STATUS_ABSENT,
	ALARM_STAFF_STATUS_EXCEPTION,
	ALARM_STUDENT_STATUS_EXCEPTION,
} from '../../../../../consts';
import { StaffActionsList } from './StaffActionsList';
import './css/StaffTable.scss';
import { ADD_NOTE_MODAL } from '../../../Modals/consts';
import { RollCallAndReunificationContext } from '../../..';

export const StaffTable = ({
	staffList,
	setStaffList,
	totalStaff,
	filterData,
	setFilterData,
	borderNone = false,
}) => {
	const { setModalToShow, setSelectedStudent } = useContext(RollCallAndReunificationContext);
	const [staffChecked, setStaffChecked] = useState(null);
	const [tableKey, setTableKey] = useState(0);

	useEffect(() => {
		forceRerender();
	}, [staffChecked]);

	// Force rerender the component to use the latest values for checkboxes
	const forceRerender = () => {
		setTableKey((prevKey) => prevKey + 1);
	};

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'staff',
			dataField: 'staff',
			text: "Staff's name",
			sort: true,
			sortCaret: (order, column) => sortingStyle(filterData.sortDir, column),
			formatter: (value, row) => (
				<TableCell
					text={`${row.person?.lastName}, ${row.person?.firstName} ${
						row.person?.middleName?.length > 0
							? row.person?.middleName.slice(0, 1) + '.'
							: ''
					}`}
				/>
			),
		},
		{
			key: 'accountedFor',
			dataField: 'accountedFor',
			text: 'Accounted for',
			style: {
				width: '125px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeStaffStatus(row, ALARM_STAFF_STATUS_ACCOUNTED)}
						checked={row.status === ALARM_STAFF_STATUS_ACCOUNTED}
						size="large"
						color="success"
					/>
				</div>
			),
		},
		{
			key: 'needsAssistance',
			dataField: 'needsAssistance',
			text: 'Needs assistance',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeStaffStatus(row, ALARM_STAFF_STATUS_NEED_ASSISTANCE)}
						checked={row.status === ALARM_STAFF_STATUS_NEED_ASSISTANCE}
						size="large"
						color="error"
					/>
				</div>
			),
		},
		{
			key: 'notOnCampus',
			dataField: 'notOnCampus',
			text: 'Not on campus',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeStaffStatus(row, ALARM_STAFF_STATUS_ABSENT)}
						checked={row.status === ALARM_STAFF_STATUS_ABSENT}
						size="large"
						color="gray"
					/>
				</div>
			),
		},
		{
			key: 'exception',
			dataField: 'exception',
			text: 'Exception',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeStaffStatus(row, ALARM_STAFF_STATUS_EXCEPTION)}
						checked={row.status === ALARM_STAFF_STATUS_EXCEPTION}
						size="large"
						color="primary"
					/>
				</div>
			),
		},
		{
			key: 'actions',
			text: 'Actions',
			dataField: 'id',
			style: {
				width: '100px',
			},
			formatter: (value, row) => <StaffActionsList student={row} />,
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			if (sortField == 'staff') {
				setFilterData((oldData) => ({
					...oldData,
					sortKey: 'staffName',
					sortDir: oldData.sortDir == 'desc' ? 'asc' : 'desc',
				}));
			}
		}
	};

	const setCurrentPage = (value) => {
		setFilterData((oldData) => ({ ...oldData, page: value }));
	};

	const setRowsPerPage = (value) => {
		setFilterData((oldData) => ({ ...oldData, perPage: value }));
	};

	const changeStaffStatus = (staff, status) => {
		const respondStatus = staff.status === status ? null : status;

		// display add note modal if the selected status is exception
		if (respondStatus === ALARM_STUDENT_STATUS_EXCEPTION) {
			setSelectedStudent(staff);
			setModalToShow(ADD_NOTE_MODAL);
		}

		setStaffChecked({
			staffId: staff.id,
		});

		setStaffList((staffList) =>
			staffList.map((staffItem) => {
				if (staffItem.id === staff.id) {
					return { ...staffItem, status: respondStatus };
				}
				return staffItem;
			}),
		);

		RapidAlarmService.updateStaffStatus(respondStatus, [filterData.alarmId], staff.id)
			.then()
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				forceRerender();
				setStaffChecked(null);
			});
	};

	return (
		<BootstrapedTable
			keyField="id"
			key={tableKey}
			className="rollcall-staff-table-list-container"
			data={staffList}
			columns={columns}
			totalRows={totalStaff}
			currentPage={filterData.page}
			rowsPerPage={filterData.perPage}
			setCurrentPage={setCurrentPage}
			setRowsPerPage={setRowsPerPage}
			onTableChange={onTableChange}
			borderNone={borderNone}
		/>
	);
};
