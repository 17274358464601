import React, { useEffect, useState, useContext } from 'react';
import { RapidAlarmService } from 'services';
import { BootstrapedTable, sortingStyle, Checkbox, TableCell } from 'componentsV2';
import {
	ALARM_STUDENT_STATUS_ACCOUNTED,
	ALARM_STUDENT_STATUS_NEED_ASSISTANCE,
	ALARM_STUDENT_STATUS_ABSENT,
	ALARM_STUDENT_STATUS_EXCEPTION,
} from '../../../../../consts';
import { VisitorsActionsList } from './VisitorsActionsList';
import './css/VisitorsTable.scss';
import { ADD_NOTE_MODAL } from '../../../Modals/consts';
import { RollCallAndReunificationContext } from '../../..';

export const VisitorsTable = ({
	visitorsList,
	setVisitorsList,
	totalVisitors,
	filterData,
	setFilterData,
	borderNone = false,
}) => {
	const { setModalToShow, setSelectedStudent } = useContext(RollCallAndReunificationContext);
	const [visitorChecked, setVisitorChecked] = useState(null);
	const [tableKey, setTableKey] = useState(0);

	useEffect(() => {
		forceRerender();
	}, [visitorChecked]);

	// Force rerender the component to use the latest values for checkboxes
	const forceRerender = () => {
		setTableKey((prevKey) => prevKey + 1);
	};

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'visitor',
			dataField: 'visitor',
			text: "Visitor's name",
			sort: true,
			sortCaret: (order, column) => sortingStyle(filterData.sortDir, column),
			formatter: (value, row) => (
				<TableCell
					text={`${row.person?.lastName}, ${row.person?.firstName} ${
						row.person?.middleName?.length > 0
							? row.person?.middleName.slice(0, 1) + '.'
							: ''
					}`}
				/>
			),
		},
		{
			key: 'accountedFor',
			dataField: 'accountedFor',
			text: 'Accounted for',
			style: {
				width: '125px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeVisitorStatus(row, ALARM_STUDENT_STATUS_ACCOUNTED)}
						checked={row.status === ALARM_STUDENT_STATUS_ACCOUNTED}
						size="large"
						color="success"
					/>
				</div>
			),
		},
		{
			key: 'needsAssistance',
			dataField: 'needsAssistance',
			text: 'Needs assistance',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() =>
							changeVisitorStatus(row, ALARM_STUDENT_STATUS_NEED_ASSISTANCE)
						}
						checked={row.status === ALARM_STUDENT_STATUS_NEED_ASSISTANCE}
						size="large"
						color="error"
					/>
				</div>
			),
		},
		{
			key: 'notOnCampus',
			dataField: 'notOnCampus',
			text: 'Not on campus',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeVisitorStatus(row, ALARM_STUDENT_STATUS_ABSENT)}
						checked={row.status === ALARM_STUDENT_STATUS_ABSENT}
						size="large"
						color="gray"
					/>
				</div>
			),
		},
		{
			key: 'exception',
			dataField: 'exception',
			text: 'Exception',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeVisitorStatus(row, ALARM_STUDENT_STATUS_EXCEPTION)}
						checked={row.status === ALARM_STUDENT_STATUS_EXCEPTION}
						size="large"
						color="primary"
					/>
				</div>
			),
		},
		{
			key: 'actions',
			text: 'Actions',
			dataField: 'id',
			style: {
				width: '100px',
			},
			formatter: (value, row) => <VisitorsActionsList visitor={row} />,
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			if (sortField == 'visitor') {
				setFilterData((oldData) => ({
					...oldData,
					sortKey: 'visitorName',
					sortDir: oldData.sortDir == 'desc' ? 'asc' : 'desc',
				}));
			}
		}
	};

	const setCurrentPage = (value) => {
		setFilterData((oldData) => ({ ...oldData, page: value }));
	};

	const setRowsPerPage = (value) => {
		setFilterData((oldData) => ({ ...oldData, perPage: value }));
	};

	const changeVisitorStatus = (visitor, status) => {
		const respondStatus = visitor.status === status ? null : status;

		// display add note modal if the selected status is exception
		if (respondStatus === ALARM_STUDENT_STATUS_EXCEPTION) {
			setSelectedStudent(visitor);
			setModalToShow(ADD_NOTE_MODAL);
		}

		setVisitorChecked({
			visitorId: visitor.id,
		});

		setVisitorsList((visitorsList) =>
			visitorsList.map((visitorItem) => {
				if (visitorItem.id === visitor.id) {
					return { ...visitorItem, status: respondStatus };
				}
				return visitorItem;
			}),
		);

		RapidAlarmService.updateVisitorStatus(filterData.alarmId, visitor.id, respondStatus)
			.then()
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				forceRerender();
				setVisitorChecked(null);
			});
	};

	return (
		<BootstrapedTable
			keyField="id"
			key={tableKey}
			className="rollcall-visitors-table-list-container"
			data={visitorsList}
			columns={columns}
			totalRows={totalVisitors}
			currentPage={filterData.page}
			rowsPerPage={filterData.perPage}
			setCurrentPage={setCurrentPage}
			setRowsPerPage={setRowsPerPage}
			onTableChange={onTableChange}
			borderNone={borderNone}
		/>
	);
};
