/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { SafetyPlanService } from 'services';
import './SafetyPlanExportPrint.scss';

// TODO:
// - Break out appropriate components
// - Validate Proptypes
// - Documentation
// - Other specific TODOS in this file.

const renderVarsInString = ({ variableValues, fileData = {}, content = '' }) => {
	let out = `${content}`.trim();
	variableValues.forEach(({ variableValue, variableName }) => {
		if (fileData[variableValue]) {
			// If we have a file, render based on type.
			const { data, type } = fileData[variableValue];
			if (type === 'image') {
				// TODO: Support embed multipage PDfs?
				out = out.replaceAll(variableName, `<img src="${data[0]}" />`);
			} else {
				out = out.replaceAll(variableName, data);
			}
		} else {
			out = out.replaceAll(variableName, variableValue);
		}
	});

	return out;
};

const Head = ({ level = 1, children }) => {
	const H = `h${level}`;
	return <H>{children}</H>;
};

const FileSubSectionPage = ({ uri }) => {
	// TODO: More things.
	return (
		<div className="page-before">
			<img src={uri} alt="" />
		</div>
	);
};

const RawHtml = ({ body }) => <div dangerouslySetInnerHTML={{ __html: body }} />;

const FileSubSection = ({ fileData: { name, type, data }, level = 1 }) => (
	<div className="file-section page-after">
		<Head {...{ level }}>Attached File: &quot;{name}&quot;</Head>
		{type === 'image' ? (
			data.map((uri, index) => <FileSubSectionPage {...{ uri, key: index }} />)
		) : (
			<RawHtml {...{ body: data }} />
		)}
	</div>
);

const Section = ({
	title = '',
	content = '',
	variableValues = [],
	children,
	fileData = {},
	subsections = [],
	level = 1,
}) => (
	<>
		{(title || content || children) && (
			<div className="section page-after">
				{title && <Head {...{ level }}>{title}</Head>}
				<RawHtml {...{ body: renderVarsInString({ content, variableValues, fileData }) }} />
				{children && <div className="children">{children}</div>}
			</div>
		)}
		{subsections.map(
			({
				title: subsectionTitle,
				content: subsectionContent,
				id,
				fileSection,
				valueFile,
				baseFile,
			}) => (
				<Section
					{...{
						title: subsectionTitle,
						content: subsectionContent,
						variableValues,
						key: id,
						level: 2,
						fileData,
					}}
				>
					{fileSection && fileData[valueFile.id || baseFile.id] && (
						<FileSubSection
							{...{ fileData: fileData[valueFile.id || baseFile.id], level: 2 }}
						/>
					)}
				</Section>
			),
		)}
	</>
);

const isCoverPageSection = ({ title = '' }) => title?.toLowerCase().includes('cover page');

const CoverPage = ({ planTitle, section = {}, variableValues, fileData }) => {
	const hasCoverPage = isCoverPageSection(section);
	const { content = '' } = section;

	return (
		((hasCoverPage || planTitle) && (
			<div className="cover-page">
				{hasCoverPage ? (
					<Section {...{ content, variableValues, fileData }} />
				) : (
					<div className="title-div page-after">{planTitle}</div>
				)}
			</div>
		)) ||
		null
	);
};

// This is a calibrated value to how many plain lines fit in the rendered TOC page
// given page margins, list padding, font, and font-size. Change those, add the new number here
// to keep page numbers accurate when TOC spans multiple pages.
const tocMaxLinesPerPage = 59;
const TocSection = ({ tocData }) => (
	<div className="page-after toc">
		{tocData?.length ? (
			<ul className="toc-list" ariaRole="list">
				{tocData.map(({ title, page, level }) => (
					<li className={`level-${level}`}>
						<a href={`#page=${page}`}>
							<span className="title">
								{title}
								<span className="leaders" ariaHidden="true" />
							</span>
							<span className="page-num">
								<span className="visually-hidden">Page&nbsp;</span>
								{/* Adjust page numbers when TOC spills past a page. */}
								{parseInt(page + 1, 10) +
									Math.floor(tocData.length / tocMaxLinesPerPage)}
							</span>
						</a>
					</li>
				))}
			</ul>
		) : (
			// This will be all users see on page 2 if there's errors in init and TOC fails.
			<p>[TABLE OF CONTENTS]</p>
		)}
	</div>
);

export const SafetyPlansDistrictExport = (props) => {
	const {
		match: {
			params: { id: planId },
		},
	} = props;
	const params = new URLSearchParams(window.location.search);
	const isBuildingToc = !!params.get('buildToc');
	const renderSectionId = parseInt(params.get('sectionId'), 10) || null;
	const renderSubsectionId = parseInt(params.get('subsectionId'), 10) || null;
	const initialized = useRef(false);
	const [tocData, setTocData] = useState([]);
	const [plan, setPlan] = useState({ content: [] });
	const [renderSectionContent, setRenderSectionContent] = useState({ content: [] });
	const [variableValues, setVariableValues] = useState([]);
	const [fileData, setFileData] = useState({});
	const [dataIsLoaded, setDataIsLoaded] = useState(false);

	useEffect(() => {
		const grabFiles = [];
		let localPlan = {};
		if (!initialized.current) {
			initialized.current = true;
			// Pull the complete plan data
			SafetyPlanService.getPlan(planId)
				.then((planData) => {
					// Attribute cover page to prevent render in list of sections.
					if (isCoverPageSection(planData.content[0])) {
						// eslint-disable-next-line no-param-reassign
						planData.content[0].isCoverPage = true;
					}

					setPlan(planData);
					localPlan = { ...planData };
					console.log(localPlan);

					// Set page title.
					document.title = planData.name;

					// Get the variable values.
					return SafetyPlanService.getVariableValues(planId);
				})
				.then(({ data: newVariableValues }) => {
					// Find all the files we need to get from file subsection.
					const { content: sections, templateVariables } = localPlan;
					sections.forEach(({ id, subsections }, index) => {
						// Set section render content if applicable.
						if (id === renderSectionId) {
							document.title = sections[index].title;
							setRenderSectionContent(sections[index]);
						}

						subsections.forEach(
							(
								{
									id: subId,
									baseFile: { id: baseFileId } = {},
									valueFile: { id: fileId } = {},
								},
								subIndex,
							) => {
								// Set section render content if applicable.
								if (subId === renderSubsectionId) {
									document.title = subsections[subIndex].title;
									setRenderSectionContent({
										content: '',
										title: '',
										subsections: [subsections[subIndex]],
									});
								}

								// Add file id if set, override then base.
								if (fileId || baseFileId) {
									grabFiles.push(fileId || baseFileId);
								}
							},
						);
					});

					// Find all files from template vars.
					templateVariables.forEach(({ content: { type: { id: typeId } }, name }) => {
						// Image variable
						if (typeId === 2) {
							const matchedAttachments = newVariableValues.filter(
								({ variableName }) => variableName === name,
							);
							grabFiles.push(
								...matchedAttachments.map(({ variableValue }) =>
									parseInt(variableValue, 10),
								),
							);
						}
					});

					// Add bonus file IDs if any.
					grabFiles.push(...localPlan.bonusFiles?.map(({ id }) => id));

					setVariableValues(newVariableValues);

					return SafetyPlanService.getFilesAsync(planId, grabFiles);
				})
				.then((filesData) => {
					const files = filesData.reduce(
						(acc, { data }) => ({
							...acc,
							...data,
						}),
						{},
					);
					setFileData(files);
					console.log({ files });
				})
				.finally(() => {
					// Build the table of contents (only if we're not building it, and not single section).
					if (!isBuildingToc && !renderSectionId) {
						SafetyPlanService.getToc(planId)
							.then(({ data: toc }) => {
								setTocData(toc);
							})
							.finally(() => {
								setDataIsLoaded(true);
							});
					} else {
						// TODO: Change the render on error to give info for user?
						// Fake loaded state on failure, allows SOME data on failure.
						setDataIsLoaded(true);
					}
				});
		}
	}, [planId]);

	return (
		<div className="safety-plan-export">
			{/* Single Section output */}
			{renderSectionId ? (
				<Section
					{...{
						...renderSectionContent,
						variableValues,
						fileData,
					}}
				/>
			) : (
				<>
					<CoverPage
						{...{
							planTitle: plan.name,
							section: plan.content[0],
							variableValues,
							fileData,
						}}
					/>
					<TocSection {...{ tocData }} />
					{plan.content.map(
						({ title, content, id, subsections, isCoverPage = false }) =>
							!isCoverPage && (
								<Section
									{...{
										title,
										content,
										subsections,
										variableValues,
										fileData,
										key: id,
									}}
								/>
							),
					)}
					{plan.bonusFiles?.length && (
						<Section title="Attached files:">
							<ul>
								{plan.bonusFiles?.map(
									({ id }) => fileData[id] && <li>{fileData[id].name}</li>,
								)}
							</ul>
						</Section>
					)}
					{plan.bonusFiles?.map(
						({ id }) =>
							fileData[id] && (
								<FileSubSection
									{...{ fileData: fileData[id], key: id, level: 2 }}
								/>
							),
					)}
				</>
			)}
			{/* Trigger for puppeteer to say the page is done */}
			{dataIsLoaded && <div className="data-loaded">&nbsp;</div>}
		</div>
	);
};
