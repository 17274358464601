import React, { useState, useEffect, useReducer, useContext } from 'react';
import '../../App/Users/AccountManager/account-manager.scss'; // to move it to common place
import { Icon } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import AvatarInitials from '../../App/AvatarInitials'; // to move it to components folder
import { FirstRespondersService } from 'services/FirstRespondersService';
import LightTableComponent from 'components/LightTable';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import firstResponderImage from 'images/first-responder.svg';
import { checkPermissionByCodeAndSites } from 'utilities/permissions';
import DeleteFirstResponderModal from './Components/DeleteFirstResponderModal';
import { Helper } from 'utilities/helper';
import { PhoneWithExtension } from 'utilities/PhoneWithExtension';
import { useSelector } from 'react-redux';
import { Breadcrumb, Button, Tooltip, FeaturedIcon } from 'componentsV2';
import { AddPrimaryContactModal } from './Components/AddPrimaryContactModal';
import { UpdatePrimaryContactModal } from './Components/UpdatePrimaryContactModal';
import { MoreSites } from './Components/MoreSites';

const UserTitle = ({ name }) => {
	return (
		<div className="user-title">
			<strong>{name}</strong>
		</div>
	);
};

const UserDetails = ({ label, value }) => {
	return (
		<div className="user-detail-box">
			<span className="user-detail-subtitle">{label}</span>
			<p className="user-detail-value value-dark">{value || 'N/A'}</p>
		</div>
	);
};

const UserInfo = ({ firstResponder, avatarImg }) => {
	return (
		<div className="user-main-info-wrapper">
			<div className="avatar-wrapper">{avatarImg}</div>
			<div className="user-info-wrapper">
				<UserTitle name={firstResponder.data.name} />
				<UserDetailsHeader firstResponder={firstResponder} />
			</div>
		</div>
	);
};

const AccountInformation = () => {
	return (
		<div className="account-information-main-container">
			<LabelText label="Account Information" style="agency-information-label-container" />
		</div>
	);
};

const UserDetailsHeader = ({ firstResponder }) => {
	return (
		<div className="user-details">
			<UserDetails
				label="Primary Contact Name"
				value={firstResponder.primaryContact.fullName}
			/>
			<UserDetails
				label="Primary Contact Email"
				value={firstResponder.primaryContact.email}
			/>
			<UserDetails
				label=" Primary Phone Number"
				value={
					<PhoneWithExtension
						phone={Helper.formatDigitsToPhone(
							firstResponder.primaryContact.work_number,
						)}
						extension={firstResponder.primaryContact.work_ext_number}
					/>
				}
			/>
		</div>
	);
};

const RemoveButton = ({ firstResponder, deleteBtnClicked }) => {
	return (
		<div>
			<Button
				text="Remove"
				type="secondaryDefault"
				onClick={deleteBtnClicked}
				disabled={!firstResponder.allowedSites || firstResponder.allowedSites.length === 0}
			/>
		</div>
	);
};

const LabelText = ({ label, style }) => {
	return <span className={style ? style : ''}>{label}</span>;
};

const FirstResponderRemoveComponent = ({ canDelete, firstResponder, deleteBtnClicked }) => {
	return (
		<>
			{canDelete && (
				<>
					<div className="remove-account-main-container">
						<LabelText label="Remove first responder" style="remove-label-container" />
					</div>
					<div className="remove-info-container">
						<div className="remove-info-sub-container">
							<LabelText label="Remove this first responder’s access to this account." />
							<RemoveButton
								firstResponder={firstResponder}
								deleteBtnClicked={deleteBtnClicked}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
};

const AgencyInformationComponent = ({ firstResponder }) => {
	const firstResponderAddress = firstResponder.data.address || 'N/A';
	const firstResponderCity = firstResponder.data.city + ', ' + firstResponder.data.state || 'N/A';
	const firstResponderZip = firstResponder.data.zip || 'N/A';
	return (
		<div>
			<LabelText label="Agency Information" style="agency-information-label-container" />
			<div className="agency-information-main-container">
				<LabelText label="Address" style="agency-labels-container" />
				<div
					style={{
						fontSize: '16px',
					}}
				>
					<div>{firstResponderAddress}</div>
					<div>{firstResponderCity}</div>
					<div>{firstResponderZip}</div>
				</div>
				<div className="site-access-container">
					<LabelText label="Sites Access" style="agency-labels-container" />
					<MoreSites
						items={firstResponder.allowedSites}
						visibleOptions={3}
						id={'firstresp'}
						isResponderSite={true}
					/>
				</div>
			</div>
		</div>
	);
};

const ContactInformationHeader = ({ onClickAddNewPrimaryContact }) => {
	return (
		<div className="contacts-information-sub-container">
			<div className="contact-label-container">
				<LabelText label="Contacts Information" />
				<Tooltip
					tooltipContent="If the current primary contact is disabled, the next person in this list will be set to primary by default."
					theme="dark"
				>
					<Icon icon="info-sign" className="icon" color="#969CA0" size={16} />
				</Tooltip>
			</div>
			<div>
				<Button
					text="Add new primary contact"
					type="secondaryDefault"
					icon="plus"
					onClick={onClickAddNewPrimaryContact}
				/>
			</div>
		</div>
	);
};

const ContactInformationComponent = ({
	firstResponder,
	onClickAddNewPrimaryContact,
	setModalToShow,
	onClickUpdatePrimaryContact,
}) => {
	return (
		<div className="contact-information-main-container">
			<ContactInformationHeader onClickAddNewPrimaryContact={onClickAddNewPrimaryContact} />
			<LightTableComponent
				setModalToShow={setModalToShow}
				data={firstResponder.data}
				onClickUpdatePrimaryContact={onClickUpdatePrimaryContact}
			/>
		</div>
	);
};

/** Gets and displays responder agencies that have been added to the current building or buildings. When logged into a regular site */
const FirstResponderView = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [modalToShow, setModalToShow] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [canDelete, setCanDelete] = useState(false);
	const [reloadFirstResponder, setReloadFirstResponder] = useState(false);
	const [contactToEdit, setContactToEdit] = useState();
	const toaster = useContext(ToasterContext);
	const permissions = useSelector(state => state.route.permissions);

	const breadCrumbs = [
		{ label: 'User management', url: '/user-management' },
		{ label: 'First responders', url: '/first-responders' },
		{
			label: 'First responder account',
		},
	];

	const firstResponderReducer = (firstResponder, action) => {
		switch (action.type) {
			case 'primaryContact':
				firstResponder['primaryContact'] = action.payload || {};
				if (
					firstResponder['primaryContact']['first_name'] &&
					firstResponder['primaryContact']['family_name']
				) {
					firstResponder['primaryContact']['fullName'] =
						firstResponder['primaryContact']['first_name'] +
						' ' +
						firstResponder['primaryContact']['family_name'];
				}
				break;
			default:
				firstResponder[action.type] = action.payload;
				break;
		}
		return {
			responderId: firstResponder.responderId,
			data: firstResponder.data,
			allowedSites: firstResponder.allowedSites,
			contacts: firstResponder.contacts,
			primaryContact: firstResponder.primaryContact,
		};
	};

	const [firstResponder, dispatchFirstResponder] = useReducer(firstResponderReducer, {
		responderId: props.match.params.id,
		data: null,
		allowedSites: [],
		contacts: [],
		primaryContact: null,
	});

	const responderId = props.match.params.id;
	const avatarImg = (
		<AvatarInitials initials={firstResponderImage} isImage={true} imageMode="profile" />
	);

	useEffect(() => {
		loadFirstResponder(responderId);
	}, [reloadFirstResponder]);

	const loadFirstResponder = async id => {
		setIsLoading(true);
		const params = { id: id };
		try {
			const response = await FirstRespondersService.getById(params);
			dispatchFirstResponder({ type: 'data', payload: response.data.primary_responder });
			dispatchFirstResponder({
				type: 'primaryContact',
				payload: firstResponder.data.primary_contact,
			});
			dispatchFirstResponder({
				type: 'allowedSites',
				payload: firstResponder.data.allowed_sites,
			});
			const hasEditPermission = checkPermissionByCodeAndSites(
				'user_edit',
				permissions,
				firstResponder.data.allowed_sites_ids,
				'some',
			);
			setCanDelete(hasEditPermission);
			const transformedData = response.data.primary_responder.contacts.map(contact => ({
				name: `${contact.first_name} ${contact.family_name}`,
				email: contact.email,
				phone: Helper.formatDigitsToPhone(contact.work_number),
				extension: contact.work_ext_number,
			}));

			const staticItem = {
				name:
					response.data.primary_responder.primary_contact.first_name +
					' ' +
					response.data.primary_responder.primary_contact.family_name,
				email: response.data.primary_responder.primary_contact.email,
				phone: Helper.formatDigitsToPhone(
					response.data.primary_responder.primary_contact.work_number,
				),
				extension: response.data.primary_responder.primary_contact.work_ext_number,
				isPrimary: true,
			};
			const contactsData = [staticItem, ...transformedData];
			dispatchFirstResponder({ type: 'contacts', payload: contactsData });
		} catch (err) {}
		setIsLoading(false);
	};

	const deleteBtnClicked = () => {
		setModalToShow('DeleteFirstResponderModal');
	};

	const DeleteFirstResponderHandler = sitesList => {
		setModalLoading(true);
		let selectedSitesIds = [];
		sitesList.map(site => {
			if (site.isSelected === true) {
				selectedSitesIds.push(site.id);
			}
		});
		FirstRespondersService.delete(responderId, selectedSitesIds)
			.then(res => {
				if (res.statusCode === 200) {
					toaster('First responder successfully deleted', Intent.SUCCESS);
					setModalLoading(false);
					setModalToShow(false);

					// if user is deleted i should be redirected to first responders list
					if (res.data.all_sites_deleted) {
						const { history } = props;
						history.push('/first-responders');
					} else {
						setReloadFirstResponder(oldValue => !oldValue);
					}
				} else {
					toaster('Something went wrong', Intent.DANGER);
					setModalLoading(false);
				}
			})
			.catch(err => {
				setModalLoading(false);
				toaster(err.error.description, Intent.DANGER);
			});
	};

	const onCloseContactInfoModal = () => {
		setModalToShow(null);
	};

	const onClickAddNewPrimaryContact = () => {
		setModalToShow('AddPrimaryContactModal');
	};

	const onClickUpdatePrimaryContact = (updateModal, row) => {
		setModalToShow(updateModal);
		setContactToEdit(row);
	};
	const onSubmitUpdatePrimaryContact = () => {
		FirstRespondersService.updatePrimaryContact(responderId, contactToEdit.pa_id)
			.then(res => {
				if (res.statusCode === 200) {
					loadFirstResponder(responderId);
					toaster(
						`You have successfully updated the primary contact for ${
							firstResponder.data.name
						} agency.`,
						Intent.SUCCESS,
						<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
					);
				}
			})
			.catch(err => {
				console.log('error', err);
				toaster(
					`There was an error updating the primary contact. Please try again.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" size="md" type="Error" shape="circleIcon" />,
				);
			})
			.finally(() => {
				onCloseContactInfoModal();
			});
	};

	return (
		<>
			{modalToShow === 'DeleteFirstResponderModal' && (
				<DeleteFirstResponderModal
					loading={modalLoading}
					setModalToShow={setModalToShow}
					DeleteFirstResponderAction={DeleteFirstResponderHandler}
					firstResponder={firstResponder.data}
				/>
			)}
			{modalToShow === 'AddPrimaryContactModal' && (
				<AddPrimaryContactModal
					loading={modalLoading}
					responderId={responderId}
					setModalToShow={setModalToShow}
					firstResponder={firstResponder}
					data={firstResponder.primaryContact}
					onCloseModal={onCloseContactInfoModal}
					loadFirstResponder={loadFirstResponder}
				/>
			)}
			{modalToShow === 'UpdatePrimaryContactModal' && (
				<UpdatePrimaryContactModal
					onCloseModal={onCloseContactInfoModal}
					onSubmitUpdatePrimaryContact={onSubmitUpdatePrimaryContact}
				/>
			)}
			<div className="account-manager-general">
				<Breadcrumb items={breadCrumbs} />
				{isLoading && (
					<div className="main-info-wrapper_Loading">
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && (
					<>
						<UserInfo firstResponder={firstResponder} avatarImg={avatarImg} />
						<div className="user-actions-wrapper">
							<div className="account-information-container">
								<div className="user-module-wrapper user-module-wrapper_OneColumn">
									<div className="module-account">
										<AccountInformation />
										<hr className="line-separator" />
										<AgencyInformationComponent
											firstResponder={firstResponder}
										/>
										<ContactInformationComponent
											setModalToShow={setModalToShow}
											onClickUpdatePrimaryContact={
												onClickUpdatePrimaryContact
											}
											firstResponder={firstResponder}
											onClickAddNewPrimaryContact={
												onClickAddNewPrimaryContact
											}
										/>
										<FirstResponderRemoveComponent
											canDelete={canDelete}
											firstResponder={firstResponder}
											deleteBtnClicked={deleteBtnClicked}
										/>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default FirstResponderView;
