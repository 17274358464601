import React, { useState, useContext, useMemo } from 'react';
import { FormModal, TextArea, ToasterContext } from 'componentsV2';
import { RollCallAndReunificationContext } from '../RollCallAndReunification';
import { RollCallDetailsContext } from '../../RollCallDetails';
import {
	ROLL_CALL_TAB_MY_CLASSES,
	ROLL_CALL_TAB_STAFF,
	ROLL_CALL_TAB_STUDENTS,
	ROLL_CALL_TAB_VISITORS,
} from '../../../consts';
import { RapidAlarmService } from 'services';
import { Intent } from '@blueprintjs/core';
import { StudentInfo } from './StudentInfo';
import './css/AddNoteModal.scss';

export const AddNoteModal = ({ contentToShow }) => {
	const { closeModal, selectedStudent } = useContext(RollCallAndReunificationContext);
	const { alarmId } = useContext(RollCallDetailsContext);
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const [note, setNote] = useState('');

	const studentType = useMemo(() => {
		switch (contentToShow) {
			case ROLL_CALL_TAB_MY_CLASSES:
			case ROLL_CALL_TAB_STUDENTS:
				return 'Student';
			case ROLL_CALL_TAB_STAFF:
				return 'Staff';
			case ROLL_CALL_TAB_VISITORS:
				return 'Visitor';
		}
		return '';
	}, []);

	const studentName = useMemo(
		() =>
			studentType === 'Staff' || studentType === 'Visitor'
				? selectedStudent.person?.fullName
				: `${selectedStudent.lastName}, ${selectedStudent.firstName} ${
						selectedStudent.middleName?.length > 0
							? selectedStudent.middleName.slice(0, 1) + '.'
							: ''
				  }`,
		[],
	);

	const onSaveHandler = () => {
		setLoading(true);
		if (contentToShow === ROLL_CALL_TAB_STAFF) {
			staffAddNote();
		} else if (contentToShow === ROLL_CALL_TAB_VISITORS) {
			visitorAddNote();
		} else {
			studentAddNote();
		}
	};

	const staffAddNote = () => {
		const params = {
			alarmId,
			text: note,
		};
		RapidAlarmService.personAddNote(selectedStudent.person?.personId, params)
			.then((response) => {
				if (response.statusCode === 201) {
					toaster(
						`You have successfully added a new note for ${studentName}.`,
						Intent.SUCCESS,
					);
				} else {
					toaster('Something went wrong!', Intent.DANGER);
				}
			})
			.catch((err) => toaster(err?.error?.description, Intent.DANGER))
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const visitorAddNote = () => {
		const params = {
			text: note,
		};
		RapidAlarmService.visitorAddNote(alarmId, selectedStudent.id, params)
			.then((response) => {
				if (response.statusCode === 201) {
					toaster(
						`You have successfully added a new note for ${studentName}.`,
						Intent.SUCCESS,
					);
				} else {
					toaster('Something went wrong!', Intent.DANGER);
				}
			})
			.catch((err) => toaster(err?.error?.description, Intent.DANGER))
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const studentAddNote = () => {
		const params = {
			alarmId,
			text: note,
		};
		RapidAlarmService.studentAddNote(selectedStudent.id, params)
			.then((response) => {
				if (response.statusCode === 201) {
					toaster(
						`You have successfully added a new note for ${studentName}.`,
						Intent.SUCCESS,
					);
				} else {
					toaster('Something went wrong!', Intent.DANGER);
				}
			})
			.catch((err) => toaster(err?.error?.description, Intent.DANGER))
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const onChangeNoteHandler = (value) => {
		setNote(value);
	};

	return (
		<FormModal
			isOpen
			scrollContent={false}
			onClose={closeModal}
			showCloseButton
			title="Add note"
			loading={loading}
			confirmBtn={{
				title: 'Save',
				onClick: onSaveHandler,
				disabled: !note.trim(),
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: closeModal,
			}}
		>
			<div className="roll-call-add-note-modal-container">
				<section className="roll-call-add-note-modal-container-student-info-container">
					<StudentInfo label={studentType} value={studentName} />
					{studentType === 'Student' && (
						<StudentInfo label="Grade" value={selectedStudent.grade} />
					)}
					{(studentType === 'Staff' || studentType === 'Visitor') && (
						<>
							<StudentInfo
								label="Email"
								value={selectedStudent.person?.emailAddress}
							/>
							<StudentInfo
								label="Phone number"
								value={selectedStudent.person?.phoneNumber}
							/>
						</>
					)}
				</section>
				<TextArea
					isRequired
					labelState={false}
					label="Note"
					placeholder="Enter your note..."
					onChange={(e) => onChangeNoteHandler(e.target.value)}
					value={note}
					className="w-100"
				/>
			</div>
		</FormModal>
	);
};
