import React, { useEffect, useState } from 'react';
import { Input, LoadingSpinner, ToggleButton, Button, Intent, Animated } from 'componentsV2';
import {
	GEOMETRY_TYPE_ICON,
	GEOMETRY_TYPE_ICONS_GROUP,
	GEOMETRY_TYPE_ROUTE,
	MAP_ICON_EDIT,
	MAP_ICON_LIST,
} from '../../consts';
import { MapOptionCard } from '../MapOptionCard';
import './css/IconEdit.scss';
import { FloorplanService } from 'services/FloorplanService';
import { deleteShapeOnMap } from '../Functions';
import { drawIcon, drawRoute } from '../../Draw';
import { ShapeContent } from '../ShapeContent';
import { UnsavedChangesModal } from '../../modals/UnsavedChangesModal';

export const IconEdit = ({
	shape,
	editPermission,
	toggleMapOption,
	floorplan,
	mapToaster,
	layer,
	transformer,
	setSelecteIcon,
	selectedIcon,
	setIconUpdated,
	iconUpdated,
	isEditMode,
	viewPermissionOnSiteId,
}) => {
	const [loading, setLoading] = useState(false);
	const [shapeData, setShapeData] = useState(selectedIcon);
	const [oldShape, setOldShape] = useState(null);
	const [currentShape, SetCurrentShape] = useState(null);
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const floorplanArchived = !!floorplan?.archivedBy;
	useEffect(() => {
		setLoading(false);
		if (currentShape && currentShape.id !== selectedIcon.id) {
			if (iconUpdated) {
				setShowUnsavedModal(true);
				setOldShape(currentShape);
			}
		}
		setShapeData({ ...selectedIcon, visible: true });
		SetCurrentShape(selectedIcon);
	}, [selectedIcon]);

	const onChangeTitleHandler = (e) => {
		setShapeData((oldValues) => ({
			...oldValues,
			title: e.target.value,
		}));
		setIconUpdated(true);
		currentShape.Newtitle = e.target.value;
	};

	const onToggleIconTitleHandler = (e) => {
		setShapeData((oldValues) => ({
			...oldValues,
			showLabel: !oldValues.showLabel,
		}));
		setIconUpdated(true);
		currentShape.NewshowLabel = !shapeData.showLabel;
	};

	const updateShapeOnMap = (iconData) => {
		// update shape position x & y & rotaion if exists
		const currentIcon = layer.children.find(
			(item) =>
				item.attrs.shape?.id === iconData.id &&
				(item.attrs.shape?.geometryType === GEOMETRY_TYPE_ICON ||
					item.attrs.shape?.geometryType === GEOMETRY_TYPE_ROUTE),
		);
		if (currentIcon) {
			iconData.shape_position_x = currentIcon.attrs.x;
			iconData.shape_position_y = currentIcon.attrs.y;
			const iconImage = currentIcon.children.find(
				(child) =>
					child.attrs.type === GEOMETRY_TYPE_ICON ||
					child.attrs.type === GEOMETRY_TYPE_ROUTE,
			);
			if (iconImage) {
				iconData.angle = iconImage.attrs.rotation;
			}
		}

		deleteShapeOnMap(iconData.id, layer, transformer.current);
		if (iconData.geometryType === GEOMETRY_TYPE_ICON) {
			drawIcon(iconData);
		} else if (iconData.geometryType === GEOMETRY_TYPE_ROUTE) {
			drawRoute(iconData);
		}
	};

	const onSaveChangesHandler = (iconData) => {
		setLoading(true);
		const params = {
			title: iconData.title,
			show_label: iconData.showLabel,
		};
		FloorplanService.updateShape(floorplan.id, iconData.id, params)
			.then((response) => {
				if (response.statusCode === 200) {
					mapToaster(`You have successfully edited "${iconData.title}"`, Intent.SUCCESS);
					updateShapeOnMap(iconData);

					/**
          As far as I can tell, this doesn't do anything and is causing an unecessary error

					let toBeSelected = layer.find(
						node => node.id() === `iconBackground-${currentShape.id}`,
					)[0];
					transformer.current.nodes([toBeSelected]);
          */
				}
			})
			.catch((err) => {
				mapToaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				setIconUpdated(false);
			});
	};

	const handleUnsavedChanges = () => {
		if (oldShape) {
			let selectedIcon = layer.find(
				(node) => node.id() === `iconBackground-${oldShape.id}`,
			)[0];
			let selectedIconData = {
				id: oldShape.id,
				title: oldShape.Newtitle ? oldShape.Newtitle : oldShape.title,
				showLabel:
					oldShape.NewshowLabel !== undefined
						? oldShape.NewshowLabel
						: oldShape.showLabel,
				visible: true,
				icon: oldShape.icon,
				geometryType: oldShape.geometryType,
			};
			onSaveChangesHandler(selectedIconData);
			selectedIcon.attrs.shape.title = selectedIconData.title;
			selectedIcon.attrs.shape.showLabel = selectedIconData.showLabel;
		}

		setShowUnsavedModal(false);
	};

	const disacardUnsavedCahnges = () => {
		let oldData = floorplan.shapes.filter((item) => item.id === oldShape.id)[0];
		if (!oldData) {
			oldData = layer.find((node) => parseInt(node.id()) === oldShape.id)[0].attrs.shape;
		}
		let selectedIcon = layer.find((node) => node.id() === `iconBackground-${oldShape.id}`)[0];
		selectedIcon.attrs.shape.title = oldData.title;
		selectedIcon.attrs.shape.showLabel = oldData.showLabel;
		setIconUpdated(false);
		setShowUnsavedModal(false);
	};

	const handleCancel = () => {
		document.getElementsByClassName('map-options-buttons')[0].style.display = 'flex';
		setSelecteIcon(null);
		setIconUpdated(false);
		transformer.current.nodes([]);
	};

	return (
		<>
			<MapOptionCard
				isOpen={true}
				title={shapeData?.title}
				loading={loading}
				onClick={() => {
					toggleMapOption(MAP_ICON_EDIT);
					handleCancel();
				}}
				headerItem={<></>}
				showLeftArrow={true}
				handleArrowClicked={() => {
					toggleMapOption(MAP_ICON_LIST);
					handleCancel();
				}}
			>
				<Animated>
					{loading && <LoadingSpinner />}
					{!loading && (
						<div className="icon-edit-container">
							{editPermission && !floorplanArchived && shapeData && isEditMode && (
								<>
									<Input
										label="Icon title"
										placeholder="Add icon title"
										isRequired={true}
										onChange={onChangeTitleHandler}
										value={shapeData.title}
									/>
									<ToggleButton
										checked={shapeData.showLabel}
										label="Show icon title"
										onChange={onToggleIconTitleHandler}
									/>
									<div className="icon-edit-container-bottom-border" />
									<div className="icon-edit-container-actions">
										<Button
											text="Cancel"
											type="secondaryDefault"
											intent="default"
											size="lg"
											onClick={() => {
												toggleMapOption(MAP_ICON_LIST);
												handleCancel();
											}}
										/>
										<Button
											text="Save"
											type="primaryDefault"
											intent="default"
											size="lg"
											onClick={() => {
												onSaveChangesHandler(shapeData);
											}}
											disabled={
												!iconUpdated ||
												(iconUpdated && shapeData.title?.length === 0) // true
											}
										/>
									</div>
								</>
							)}
							{selectedIcon && (
								<ShapeContent
									floorplan={floorplan}
									editPermission={editPermission}
									siteId={floorplan.building.id}
									selectedShape={selectedIcon}
									shapeId={selectedIcon?.id}
									isEditMode={isEditMode}
									viewPermissionOnSiteId={viewPermissionOnSiteId}
								/>
							)}
						</div>
					)}
				</Animated>
			</MapOptionCard>
			{showUnsavedModal && (
				<UnsavedChangesModal
					saveChangesHandler={handleUnsavedChanges}
					discardChangesHandler={disacardUnsavedCahnges}
					shapeType={'icon'}
				/>
			)}
		</>
	);
};
