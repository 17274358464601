import { drawIcon, drawRoute, drawText } from '../../Draw';
import {
	GEOMETRY_TYPE_ICON,
	GEOMETRY_TYPE_ROUTE,
	GEOMETRY_TYPE_TEXT,
	ICON_TYPE_CAMERA,
	ICON_TYPE_OTHERS,
	ICON_TYPE_ROUTE,
} from '../../consts';
import { floorplanDetails } from '../../CanvasDrawFn';
import { addShape } from './addShape';
import { FloorplanService } from 'services/FloorplanService';

export const onDragShapeHandler = (event, shape) => {
	event.dataTransfer.setData('application/json', JSON.stringify(shape));
};

export const onDropShapeEvent = (e, pointerPosition, setHasChange, showModal, addToBulkSave) => {
	const draggedShape = JSON.parse(e.dataTransfer.getData('application/json'));

	if (draggedShape.iconType === ICON_TYPE_OTHERS || draggedShape.iconType === ICON_TYPE_CAMERA) {
		return addIcon(draggedShape, pointerPosition);
	} else if (draggedShape.iconType === ICON_TYPE_ROUTE) {
		return addRoute(draggedShape, pointerPosition);
	} else if (draggedShape.geometryType == 1) {
		return addText(draggedShape, pointerPosition, setHasChange, showModal, addToBulkSave);
	}
};

const addIcon = (draggedShape, pointerPosition) => {
	let type = '';
	const shape = {
		title: draggedShape.title,
		shape_position_x: pointerPosition.x,
		shape_position_y: pointerPosition.y,
		x: pointerPosition.x,
		y: pointerPosition.y,
		icon: { iconURL: draggedShape.iconURL },
		draggable: true,
		visible: true,
		geometryType: GEOMETRY_TYPE_ICON,
		icon_id: draggedShape.id,
		showLabel: false,
	};

	if (draggedShape.iconType === ICON_TYPE_CAMERA) {
		type = 'Camera';
	} else if (draggedShape.iconType === ICON_TYPE_ROUTE) {
		type = 'Route';
	} else {
		type = 'Other';
	}
	shape.icon.type = type;

	return addShape(floorplanDetails.id, shape).then((response) => {
		const newShape = {
			...shape,
			id: response.data.id,
		};
		drawIcon(newShape);
		return {
			...response,
			data: {
				...response.data,
				shape: newShape,
			},
		};
	});
};

const addRoute = (draggedShape, pointerPosition) => {
	const shape = {
		title: draggedShape.title,
		shape_position_x: pointerPosition.x,
		shape_position_y: pointerPosition.y,
		x: pointerPosition.x,
		y: pointerPosition.y,
		icon: { iconURL: draggedShape.iconURL },
		draggable: true,
		visible: true,
		geometryType: GEOMETRY_TYPE_ROUTE,
		icon_id: draggedShape.id,
		showLabel: false,
	};

	return addShape(floorplanDetails.id, shape).then((response) => {
		const newShape = {
			...shape,
			id: response.data.id,
		};
		drawRoute(newShape);
		return {
			...response,
			data: {
				...response.data,
				shape: newShape,
			},
		};
	});
};

const addText = (draggedText, pointerPosition, setHasChange, showModal, addToBulkSave) => {
	let textToDraw = {
		id: Math.random(),
		title: draggedText.title,
		shape_position_x: pointerPosition.x,
		shape_position_y: pointerPosition.y,
	};

	let textNode = drawText(setHasChange, showModal, textToDraw, addToBulkSave);
	let textAttrs = textNode.getAttrs();

	var shape = {
		type: GEOMETRY_TYPE_TEXT,
		title: draggedText.title,
		position_x: textAttrs.x,
		position_y: textAttrs.y,
		is_shape_draggable: true,
		fontsize: textAttrs.fontSize,
		fill: draggedText.fill || 'black',
		opacity: draggedText.opacity,
		geometryType: GEOMETRY_TYPE_TEXT,
		visible: true,
		coord_lat_long: '[]',
	};

	return FloorplanService.addShape(floorplanDetails.id, shape).then((res) => {
		textNode.setAttr('id', res.data.id);
		const newShape = {
			...shape,
			id: res.data.id,
		};
		return {
			...res,
			data: {
				...res.data,
				shape: newShape,
			},
		};
	});
};
