import Konva from 'konva';
import { floorplanDetails } from '../CanvasDrawFn';
import { WELL_PROPORTIONED_AREA } from 'utilities/constants';

// Relative scales based on a well-proportioned area
const scalingRatioLabelFont = 36 ** 2 / WELL_PROPORTIONED_AREA;
const scalingRatioLabelPadding = 18 ** 2 / WELL_PROPORTIONED_AREA;
const scalingRatioLabelHeight = 60 ** 2 / WELL_PROPORTIONED_AREA;
const scalingRatioLabelRadius = 32 ** 2 / WELL_PROPORTIONED_AREA;
const scalingRatioLabelStroke = 3 ** 2 / WELL_PROPORTIONED_AREA;
const scalingRatioLabelSpacing = 45 ** 2 / WELL_PROPORTIONED_AREA;

export const drawLabel = (title, parentShape, paddingTop = 0) => {
	const floorplanArea = floorplanDetails.width * floorplanDetails.height;

	const labelGroup = new Konva.Group({
		draggable: false,
		listening: false,
		scalable: false,
	});

	const labelBorder = new Konva.Rect({
		stroke: '#B3D8DD',
		strokeWidth: Math.sqrt(scalingRatioLabelStroke * floorplanArea),
		fill: '#E6F2F4',
		height: Math.sqrt(scalingRatioLabelHeight * floorplanArea),
		cornerRadius: Math.sqrt(scalingRatioLabelRadius * floorplanArea),
	});

	const labelText = new Konva.Text({
		x: labelBorder.x(),
		y: labelBorder.y(),
		text: title,
		fontSize: Math.sqrt(scalingRatioLabelFont * floorplanArea),
		fontFamily: 'Nunito Sans',
		fontStyle: 'normal',
		fill: '#007E8F',
		padding: Math.sqrt(scalingRatioLabelPadding * floorplanArea),
		textAlign: 'center',
		justifyContent: 'center',
		verticalAlign: 'middle',
	});
	labelBorder.width(labelText.width() + 2);

	labelGroup.add(labelBorder);
	labelGroup.add(labelText);

	// Center the Label
	const xPos = parentShape.height() / 2 + parentShape.x() - labelBorder.width() / 2;
	const yPos = parentShape.y() - Math.sqrt(scalingRatioLabelSpacing * floorplanArea);
	labelGroup.x(xPos);
	labelGroup.y(yPos);

	return labelGroup;
};
