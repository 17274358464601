import { FloorplanService } from 'services/FloorplanService';
import {
	calculateLabelCenter,
	drawAreaLabel,
	stopEventListener,
	getAreaNewPosition,
} from '../../Draw';
import { GEOMETRY_TYPE_POLYGON } from '../../consts';
import { Intent } from '@blueprintjs/core';
export const updateUnSavedArea = (
	floorplanId,
	layer,
	stage,
	selectedArea,
	legendListWithDescription,
	mapToaster,
) => {
	let area = layer.find(node => parseInt(node.id()) === selectedArea.id)[0];
	let selectedColor = legendListWithDescription.filter(
		item => '#' + item.hex === area.attrs.fill,
	)[0];
	let newPoints = [];
	if (area.attrs?.x && area.attrs?.y) {
		//  means positon of the area changed
		newPoints = getAreaNewPosition(area.attrs.points, area.attrs.x, area.attrs.y);
	}
	let points = newPoints.length > 0 ? newPoints : area.attrs.points;
	let areaData = {
		id: parseInt(area.attrs.id),
		type: 'area',
		title: area.attrs.title,
		is_shape_draggable: false,
		points: points,
		color_id: selectedColor.id,
		selectedColor: selectedColor,
		coord_lat_long: JSON.stringify(area.attrs.shape.coord_latlng),
		show_label: area.attrs?.showLabel,
		visible: true,
		geometryType: GEOMETRY_TYPE_POLYGON,
	};

	FloorplanService.updateShape(floorplanId, areaData.id, areaData).then(resp => {
		if (resp.statusCode === 200) {
			mapToaster(`You have successfully edited this area.`, Intent.SUCCESS);
			updateOnMap(layer, areaData, stage);
		}
	});
};

const updateOnMap = (layer, areaData, stage) => {
	let updatedArea = layer.find(node => parseInt(node.id()) === parseInt(areaData.id))[0];
	updatedArea.attrs.title = areaData.title;
	updatedArea.attrs.shape.title = areaData.title;
	updatedArea.attrs.shape.colorId = areaData.selectedColor.id;
	updatedArea.attrs.shape.color = areaData.selectedColor.hex;
	updatedArea.attrs.shape.showLabel = areaData.show_label;
	updatedArea.attrs.shape.positions = JSON.stringify(areaData.points);
	updatedArea.draggable(false);

	let labelGroup = layer.find(node => node.id() === `labelGroup-${updatedArea.attrs.id}`)[0];
	let areaGroup = layer.find(node => node.id() === `areaGroup-${updatedArea.attrs.id}`)[0];
	if (!labelGroup) {
		labelGroup = drawAreaLabel(areaData, updatedArea);
		areaGroup.add(labelGroup);
	}
	if (labelGroup) {
		const textShape = labelGroup.children.find(child => child instanceof Konva.Text);
		if (textShape) {
			textShape.text(areaData.title);
		}
		const labelBorder = labelGroup.children.find(child => child instanceof Konva.Rect);
		if (labelBorder) {
			labelBorder.width(textShape.width());
			const labelCenter = calculateLabelCenter(updatedArea, labelBorder);
			labelGroup.x(labelCenter[0]);
			labelGroup.y(labelCenter[1]);
		}
	}

	if (updatedArea.attrs.shape.showLabel) {
		labelGroup.show();
	} else {
		labelGroup.hide();
	}

	stopEventListener('click', stage, 'addNewPointInstance');
	stopEventListener('mousedown', stage, 'handleAddNewArea');
	let anchors = layer.find(node => node.name() === 'anchor');
	anchors.map(anchor => {
		anchor.destroy();
	});
};
